<template>
  <NestedData />
</template>

<script>
import NestedData from "@/own/components/shipping/regions/NestedData";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "Countries",
  components: { NestedData },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
