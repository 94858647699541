import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  GET_REGIONS,
  GET_COUNTRIES,
  SET_SELECTED_REGION_ID,
} from "@/core/services/store/shipping_regions.module";
// import swalCountry from "@/core/services/AlertServices/SwalCountry";

export default {
  data: () => ({
    countryRef: null,
  }),
  mounted() {
    this.$store.dispatch(GET_REGIONS).finally(() => {
      this.$store.commit(SET_PAGE_LOADING, false);
    });
  },
  methods: {
    handleAddFunction(itemType) {
      if (itemType === "region") this.$refs.addRegionDialog.toggleModal();
      else this.$refs.addAreaDialog.toggleModal();
    },
    getCountries(id, event) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;
      this.$store.commit(SET_SELECTED_REGION_ID, id);
      this.$store.commit(SET_PAGE_LOADING, true);
      if (this.countryRef) {
        this.countryRef.classList.remove("bg-light-info");
        this.countryRef.firstElementChild.firstElementChild.style.color =
          "rgba(0, 0, 0, 0.54)";
      }
      let payload = { region_id: id, type: "country" };
      this.$store.dispatch(GET_COUNTRIES, payload).finally(() => {
        elem.classList.add("bg-light-info");
        elem.firstElementChild.firstElementChild.style.color = "#8950fc";
        this.countryRef = elem;
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
  },
  computed: {
    tableProperties: function () {
      return {
        title: "Regions",
        description: "Manage Regions",
      };
    },
  },
};
